import React from "react";
import "./Footer.css";
import AnimatedIconContainer from "../custom/AnimatedIconContainer";
import styled from "styled-components";

const IconContainer = styled.span`
  color: ${(props) => props.theme.primaryFontColor};
  display: inline-block;
  margin-right: 20px;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
`;

function Footer(props) {
  const githubUrl = "https://github.com/arnxv0";
  const instagramUrl = "";
  const linkedinUrl = "https://www.linkedin.com/in/arnavdewan";
  const emailUrl = "mailto:contact@arnavdewan.dev";

  function openPage(url) {
    window.open(url, "_blank");
  }

  return (
    <>
      <div className="footer-container">
        <IconContainer onClick={() => openPage(githubUrl)}>
          <AnimatedIconContainer>
            <i className="fab fa-github fa-2x"></i>
          </AnimatedIconContainer>
        </IconContainer>
        <IconContainer onClick={() => openPage(linkedinUrl)}>
          <AnimatedIconContainer>
            <i className="fab fa-linkedin fa-2x"></i>
          </AnimatedIconContainer>
        </IconContainer>
        <IconContainer onClick={() => openPage(emailUrl)}>
          <AnimatedIconContainer>
            <i className="far fa-envelope fa-2x"></i>
          </AnimatedIconContainer>
        </IconContainer>
        {/* <IconContainer onClick={() => openPage(instagramUrl)}>
          <AnimatedIconContainer>
            <i className="fab fa-instagram fa-2x"></i>
          </AnimatedIconContainer>
        </IconContainer> */}
      </div>
    </>
  );
}

export default Footer;
