import React, { useState } from "react";
import styled from "styled-components";
import UseWindowDimensions from "../custom/UseWindowDimentions";
import AnimatedIconContainer from "../custom/AnimatedIconContainer";
import SkillCard from "../custom/SkillCard";
import { rgba } from "polished";
import emailjs from "emailjs-com";

// Icons
import javascriptIcon from "./assets/skillIcons/javascriptIcon.png";
import htmlIcon from "./assets/skillIcons/htmlIcon.png";
import cssIcon from "./assets/skillIcons/cssIcon.png";
import reactIcon from "./assets/skillIcons/reactIcon.png";
import flutterIcon from "./assets/skillIcons/flutterIcon.png";
import androidIcon from "./assets/skillIcons/androidIcon.png";
import mongodbIcon from "./assets/skillIcons/mongodbIcon.png";
import nodeIcon from "./assets/skillIcons/nodeIcon.png";
import pythonIcon from "./assets/skillIcons/pythonIcon.png";
import cIcon from "./assets/skillIcons/cIcon.png";
import firebaseIcon from "./assets/skillIcons/firebaseIcon.png";
import googelCloudIcon from "./assets/skillIcons/googelCloudIcon.png";
import flaskIcon from "./assets/skillIcons/flaskIcon.png";

const ResumeSlidingPageContainer = styled.div`
  position: absolute;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: ${(props) => props.theme.resumeBackground};
  transition: 0.3s;
  overflow-y: auto;
  overflow-x: hidden;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    background-color: ${(props) => props.theme.resumeBackground};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollBar};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollBarHover};
  }

  @media (max-width: 950px) {
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 5%;
  left: 4%;
  height: 80%;

  @media (max-width: 950px) {
    top: 3%;
    left: 20px;
    height: 0;
  }
`;

const BackButton = styled.button`
  position: sticky;
  position: -webkit-sticky;
  top: 5%;
  color: ${(props) => props.theme.primaryFontColor};
  background-color: ${(props) => props.theme.lowEmFc};
  border-radius: 50%;
  height: 70px;
  width: 70px;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border-style: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    position: static;
    height: 50px;
    width: 50px;
  }
`;

const SkillsTitle = styled.p`
  margin: 5% 0 0 15%;
  font-family: "Montserrat";
  color: ${(props) => props.theme.resumeTabHighEmFc};
  font-size: 2rem;
  font-weight: bolder;

  @media (max-width: 950px) {
    margin: 7% 0 0 0;
    font-size: 2rem;
    text-align: center;
  }
`;

const SkillsContainer = styled.div`
  margin: 10px 0 0 15%;
  width: 65%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 950px) {
    margin: 30px 0 0 30px;
    font-size: 1rem;
    width: 90%;
    justify-content: center;
  }
`;

const SkillCardContainer = styled.div`
  margin: 20px 30px 20px 0;

  @media (max-width: 950px) {
    margin: 10px 30px 10px 0;
  }
`;

const skills = [
  { icon: javascriptIcon, name: "Javacript" },
  { icon: htmlIcon, name: "HTML" },
  { icon: cssIcon, name: "CSS" },
  { icon: reactIcon, name: "React js" },
  { icon: flutterIcon, name: "Flutter" },
  { icon: androidIcon, name: "Android java" },
  { icon: mongodbIcon, name: "MongoDB" },
  { icon: nodeIcon, name: "Node js" },
  { icon: pythonIcon, name: "Python" },
  { icon: cIcon, name: "C / C++" },
  { icon: firebaseIcon, name: "Firebase" },
  { icon: flaskIcon, name: "Flask" },
  { icon: googelCloudIcon, name: "Google Cloud" },
];

const ResumeButton = styled.button`
  margin: 20px 0 0 15%;
  color: ${(props) => props.theme.secondaryFontColor};
  background-color: ${(props) => props.theme.resumeButtonBackground};
  height: 70px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${(props) => props.theme.secondaryFontColor};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 20px auto 0 auto;
    font-size: 1rem;
    display: block;
  }
`;

const ContactMeTitle = styled.p`
  margin: 4% 0 0 15%;
  font-family: "Montserrat";
  color: ${(props) => props.theme.resumeTabHighEmFc};
  font-size: 2rem;
  font-weight: bolder;

  @media (max-width: 950px) {
    margin: 15% 0 0 0;
    font-size: 2rem;
    text-align: center;
  }
`;

const ContactMeContainer = styled.div`
  margin: 20px 0 5% 15%;
  width: 40%;

  @media (max-width: 950px) {
    margin: 30px auto 5% auto;
    width: 75%;
    justify-content: center;
  }
`;

const NameInput = styled.input`
  box-sizing: border-box;
  font-size: 1.3rem;
  padding: 15px 20px;
  border-style: none;
  border-radius: 10px;
  background: none;
  font-family: "Montserrat";
  width: 100%;
  color: ${(props) => props.theme.resumeTabMediumEmFc};
  background-color: rgb(255, 255, 255, 0.1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
  border: solid 1px transparent;
  transition: border 0.3s ease-out;

  &:focus {
    outline: none;
    border: solid 1px ${(props) => rgba(props.theme.secondaryFontColor, 0.6)};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out,
      border 0.3s ease-out;
  }

  @media (max-width: 950px) {
    font-size: 1.1rem;
  }
`;

const EmailInput = styled.input`
  box-sizing: border-box;
  font-family: "Montserrat";
  margin-top: 20px;
  font-size: 1.3rem;
  padding: 15px 20px;
  border-style: none;
  border-radius: 10px;
  background: none;
  width: 100%;
  color: ${(props) => props.theme.resumeTabMediumEmFc};
  background-color: rgb(255, 255, 255, 0.1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
  border: solid 1px transparent;
  transition: border 0.3s ease-out;

  &:focus {
    outline: none;
    border: solid 1px ${(props) => rgba(props.theme.secondaryFontColor, 0.6)};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out,
      border 0.3s ease-out;
  }

  @media (max-width: 950px) {
    font-size: 1.1rem;
  }
`;

const MessageInput = styled.textarea`
  box-sizing: border-box;
  font-family: "Montserrat";
  margin-top: 20px;
  font-size: 1.3rem;
  padding: 15px 20px;
  border-style: none;
  border-radius: 10px;
  background: none;
  width: 100%;
  height: 200px;
  resize: none;
  color: ${(props) => props.theme.resumeTabMediumEmFc};
  background-color: rgb(255, 255, 255, 0.1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.3) 0px 3px 6px;
  border: solid 1px transparent;
  transition: border 0.3s ease-out;

  &:focus {
    outline: none;
    border: solid 1px ${(props) => rgba(props.theme.secondaryFontColor, 0.6)};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out,
      border 0.3s ease-out;
  }

  @media (max-width: 950px) {
    font-size: 1.1rem;
  }
`;

const SendMessageButton = styled.button`
  margin: 10px auto 0 auto;
  color: ${(props) => props.theme.secondaryFontColor};
  background-color: ${(props) => props.theme.resumeButtonBackground};
  padding: 15px 15px;
  width: 200px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  outline: none;
  border: solid 1px ${(props) => props.theme.secondaryFontColor};
  display: block;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 950px) {
    margin: 10px auto 0 auto;
    padding: 10px 10px;
    width: 150px;
    font-size: 1.1rem;
  }
`;

const AboutSectionContainer = styled.div`
  margin: 10px 0 0 15%;
  width: 65%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 950px) {
    margin: 30px 0 0 30px;
    font-size: 1rem;
    width: 90%;
    justify-content: center;
  }
`;

const AboutSectionImg = styled.img``;

const AboutSectionText = styled.p`
  color: white;
`;

function getSkillCard(skill, key) {
  return (
    <SkillCardContainer key={key}>
      <SkillCard name={skill.name} icon={skill.icon} key={key} />
    </SkillCardContainer>
  );
}

function ResumeSlidingPage({ peakResume, showResume, hideShowResume }) {
  let windowWidth = UseWindowDimensions().width;

  const [contactMessage, setContactMessage] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [contactMessageHeading, setContactMessageHeading] = useState(
    windowWidth > 950 ? "Let's brainstorm together" : "Contact me"
  );

  function handleInputChange(event) {
    const { value, name } = event.target;
    setContactMessage((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function sendMessage() {
    if (
      contactMessage.message === "" ||
      contactMessage.email === "" ||
      contactMessage.name === ""
    ) {
      setContactMessageHeading(
        windowWidth > 950 ? "Can't leave any field empty!" : "Fill all fields"
      );
    } else {
      emailjs
        .send(
          "service_qvmampu",
          "template_6gm5n47",
          {
            name: contactMessage.name,
            email: contactMessage.email,
            message: contactMessage.message,
          },
          "user_FOWz2c5HM7XZhc6Kd22id"
        )
        .then(
          (result) => {
            setContactMessageHeading(
              windowWidth > 950
                ? "I'll get back to you soon :)"
                : "Message sent"
            );
            setContactMessage({
              name: "",
              email: "",
              message: "",
            });
          },
          (error) => {
            setContactMessageHeading(
              windowWidth > 950 ? "Oops! Try again later :(" : "Not sent"
            );
          }
        );
    }
  }

  function openResume() {
    const url = "https://www.linkedin.com/in/arnavdewan";
    window.open(url, "_blank");
  }

  let peakResumeStyle;
  if (windowWidth < 950) {
    peakResumeStyle = {
      transform: showResume
        ? `translateX(-100%)`
        : peakResume
        ? `translateX(-3.2em)`
        : `none`,
    };
  } else {
    peakResumeStyle = {
      transform: showResume
        ? `translateX(-85%)`
        : peakResume
        ? `translateX(-3.2em)`
        : `none`,
    };
  }
  return (
    <ResumeSlidingPageContainer style={peakResumeStyle}>
      <BackButtonContainer>
        <BackButton onClick={hideShowResume}>
          <AnimatedIconContainer>
            <i
              className={
                windowWidth > 950
                  ? "fas fa-chevron-right fa-3x"
                  : "fas fa-chevron-right fa-2x"
              }
            ></i>
          </AnimatedIconContainer>
        </BackButton>
      </BackButtonContainer>
      {/* <AboutSectionContainer>
        <AboutSectionImg />
        <AboutSectionText>
          Im 20 and im a blip blop, haha gg, Im 20 and im a blip blop, haha gg,
          Im 20 and im a blip blop, haha gg,Im 20 and im a blip blop, haha gg,Im
          20 and im a blip blop, haha gg,Im 20 and im a blip blop, haha gg,Im 20
          and im a blip blop, haha gg
        </AboutSectionText>
      </AboutSectionContainer> */}
      <SkillsTitle>My skills</SkillsTitle>
      <SkillsContainer>{skills.map(getSkillCard)}</SkillsContainer>
      <ResumeButton onClick={openResume}>Show Resume</ResumeButton>
      <ContactMeTitle>{contactMessageHeading}</ContactMeTitle>
      <ContactMeContainer>
        <NameInput
          placeholder="Name"
          name="name"
          type="text"
          value={contactMessage.name}
          onChange={handleInputChange}
        />
        <EmailInput
          placeholder="Email"
          name="email"
          type="email"
          value={contactMessage.email}
          onChange={handleInputChange}
        />
        <MessageInput
          placeholder="Message"
          name="message"
          type="text"
          value={contactMessage.message}
          onChange={handleInputChange}
        />
        <SendMessageButton onClick={sendMessage}>Send</SendMessageButton>
      </ContactMeContainer>
    </ResumeSlidingPageContainer>
  );
}

export default ResumeSlidingPage;
